import {Button, Form} from "react-bootstrap";
import React, { useState } from "react";
import Axios from "axios";


const ContactForm = () => {
    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
    });
    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg }
        });
        if (ok) {
            form.reset();
        }
    };
    const handleOnSubmit = e => {
        e.preventDefault();
        console.log('form clicked');
        const form = e.target;
        setServerState({ submitting: true });
        Axios({
            method: "post",
            url: "https://usebasin.com/f/796f829f26fb",
            data: new FormData(form),
            crossDomain: true
        })
            .then(r => {
                handleServerResponse(true, "Success - your message was sent", form);
            })
            .catch(r => {
                handleServerResponse(false, r.response.data.error, form);
                console.log(r.response);
            });
    };
    return (
        <>
            <form onSubmit={handleOnSubmit}>
                <Form.Group className="mb-1">
                    <Form.Control type="text" placeholder="Name & Surname" name="Name" required="required"  />
                </Form.Group>
                <Form.Group className="mb-1">
                    <Form.Control type="email" placeholder="Email address" name="Email Address" required="required"/>
                </Form.Group>
                <Form.Group className="mb-1">
                    <Form.Control type="text" placeholder="Phone number" name="Phone Number" required="required" />
                </Form.Group>
                <Form.Group className="mb-1" controlId="formBasicMessage">
                    <Form.Control as="textarea" type="text" placeholder="Message" name="Message" required="required"/>
                </Form.Group>
                <Button variant="secondary" className="btn-block" type="submit" disabled={serverState.submitting}>
                    Submit
                </Button>

                {serverState.status && (
                    <div className={!serverState.status.ok ? "bg-danger p-3 rounded mt-3 text-white" : "bg-primary p-3 rounded mt-3 text-white"}>
                        {serverState.status.msg}
                    </div>
                )}
            </form>
        </>
    );
};

export default ContactForm;
