import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import SEO from "../components/seo"
import Layout from "../components/layout";
import ContactForm from "../components/contactForm";
import {graphql} from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import setupIconLibrary from "../utils/iconLibrary"
setupIconLibrary()

const ContactPage = () => {

    return (
        <Layout pageInfo={{pageName: "index"}}>
            <SEO title="Contact Us" description="To get in touch with us simply call the number below or fill out the form on the left and we will get back to you ASAP" />

            <Container className="slice">
                <Row className="justify-content-between">
                    <Col lg={8}>
                        <h1 className="font-weight-800 mb-3">Contact Info</h1>
                        <p>To get in touch with us simply call the number below or fill out the form and we will get back to you ASAP.</p>

                        <Row>
                            <Col sm={6}>
                                <h5>iCashCars.co.uk</h5>
                                <ul className="list-unstyled">
                                    <li><a href="tel:0800 2884966"><FontAwesomeIcon icon="phone" className="" /> 0800 2884966</a></li>
                                    <li><a href="https://wa.me/447835767690" target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={['fab', 'whatsapp']} className="" /> Chat on WhatsApp</a></li>
                                    <li><a href="mailto:hello@icashcars.co.uk"><FontAwesomeIcon icon="envelope" className="" /> hello@icashcars.co.uk</a></li>
                                </ul>

                                <p><FontAwesomeIcon icon="map-marker" className="" /> Unit 1, Pinecopse <br/>
                                Nine Mile Ride<br/>
                                Wokingham<br/>
                                Berkshire<br/>
                                RG40 3ND</p>
                            </Col>
                            <Col sm={6}>
                                <div className="embed-responsive embed-responsive-16by9">
                                <iframe title="Google Map" width="100%" height="600"
                                        src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;coord=51.379055,-0.824638&amp;q=Unit%201%2C%20Pinecopse%20Nine%20Mile%20Ride%20Wokingham%20Berkshire%20RG40%203ND+(iCashCars)&amp;ie=UTF8&amp;t=h&amp;z=15&amp;iwloc=B&amp;output=embed"
                                        frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"><a
                                    href="http://www.gps.ie/">Google Maps coordinates</a></iframe>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={4}>
                        <div className="rounded p-4 bg-gray-100">
                            <h4 className="text-primary-dark mb-3">
                                Send us a message
                            </h4>
                            <ContactForm />


                        </div>
                    </Col>
                </Row>


            </Container>

        </Layout>
    )
}

export default ContactPage
